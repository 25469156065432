<template>
  <div>
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
            ref="ps"
          >
            <!-- Filters  :to="locationClicked(location)" -->
            <b-list-group
              flush
              class="list-group-locations"
              :style="{ 'max-height': isLocalLayout ? '450px' : '210px' }"
            >
              <div v-for="location in locations" :key="location.id" class="row">
                <b-list-group-item
                  :class="[
                    getLocationStatusClass(location),
                    getSelected(location) ? 'selected' : '',
                  ]"
                  class="pointer col-10"
                  style="border:0;"
                  @click.prevent="zoomMap(location)"
                >
                  <feather-icon
                    :icon="
                      getSelected(location) ? 'ArrowRightIcon' : 'MapPinIcon'
                    "
                    size="18"
                    class="mr-75"
                  />
                  <span class="align-text-bottom line-height-1">{{
                    location.name
                  }}</span>
                </b-list-group-item>
                <slot
                  class="col-1"
                  name="list-item-additional-content"
                  :location="location"
                >
                </slot>
              </div>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import {
  BButton,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { getLocationStatus } from '@/utils';
import { ref, computed } from '@vue/composition-api';
import store from '@/store';

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormCheckbox,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    locations: {
      type: Array,
      default: [],
    },
  },
  setup(props, context) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const getLocationStatusClass = location => {
      const statusClasses = [
        'status-green',
        'status-orange',
        'status-red',
        'status-yellow',
        'status-blue',
      ];
      const status = getLocationStatus(location);
      return statusClasses[status - 1];
    };

    const getSelected = location => {
      return location.id == context.root.$route.params.id;
    };

    const zoomMap = location => {
      context.emit('zoomIn', location);
    };

    const ps = ref(null);
    const updateScrollBar = () => {
      ps.update();
    };

    const isLocalLayout = computed(() => {
      return store.state.appConfig.layout.isLocalLayout;
    });

    return {
      // UI
      getSelected,
      perfectScrollbarSettings,
      getLocationStatusClass,
      zoomMap,
      ps,
      updateScrollBar,
      isLocalLayout,
    };
  },
};
</script>

<style lang="scss">
@import 'scss/variables/_variables';
.list-group-locations > .list-group-item {
  border-width: 0 0 0px;

  &:hover {
    color: $primary !important;
    background-color: #ffffff !important;
  }
}
.list-group-locations > .router-link-active {
  color: $primary !important;
  background-color: #ffffff !important;
}

.status-red {
  color: red;
}

.status-green {
  color: green;
}
.pointer {
  cursor: pointer;
}

.status-blue {
  color: #2c98d4;
}
.status-green {
  color: #28c76f;
}
.status-red {
  color: #ea5455;
}
.status-orange {
  color: #eaa454;
}
.status-yellow {
  color: #ecf01c;
}
.selected {
  font-weight: bold;
}
.ps-container > .ps__rail-y {
  opacity: 0.6;
}
</style>
